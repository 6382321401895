<template>
  <app-page-layout
    v-bind="content"
    class="page-imprint"
  >
    <div
      v-for="({title, text}, index) in content.data"
      :key="index"
      class="page-imprint__item"
    >
      <div class="page-imprint__item-container">
        <div class="page-imprint__item-title">{{ title }}</div>
        <div class="page-imprint__item-text" v-html="text"/>
      </div>
    </div>
  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';

export default {
  components: {
    AppPageLayout,
  },
  computed: {
    content() {
      return this.$t('page.imprint');
    },
  },
};
</script>

<style lang="scss">
.page-imprint {
  .app-page-layout {
    &__header {
      &-control {
        display: none;
      }
    }
  }

  &__item {
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 16px;

    background-color: #F8F7F7;

    &-container {
      @include container;
    }

    &-title {
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 28px;
      line-height: 138.19%;
      color: #CA1025;
    }

    &-text {
      font-weight: 500;
      font-size: 20px;
      line-height: 138.19%;
      color: #343434;

      white-space: pre-line;
    }
  }

  @include media-bp(tab) {
    &__item {
      &-title {
        margin-bottom: 12px;
        font-size: 20px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }

  @include media-bp(mob) {
    &__item {
      &-title {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 138.19%;
      }

      &-text {
        font-size: 16px;
        line-height: 138.19%;
      }
    }
  }
}
</style>
